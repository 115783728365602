<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/PageHeader";
import appConfig from "../../../app.config";

import axios from 'axios';
import Container from "@/components/Container.vue";

export default {
  page: {
    title: "Article",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      title: "Article",
      data: {
        article: {},
        blog: {}
      }
    };
  },
  components: {
    Layout,
    PageHeader,
    Container,
  },
  created: function () {
    this.loadingArticle();
  },
  methods: {
    loadingArticle() {
      axios.get('/api/blog/' + this.$route.params.blogLink + '/' + this.$route.params.articleLink)
        .then((response) => {
          this.data = response.data;
        })
        .catch(function () {
        });
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" v-if="false" />

    <div class="profile-foreground position-relative mx-n4 mt-n4">
      <div class="profile-wid-bg"><img v-bind:src="data.article.urlCover" alt="" class="profile-wid-img"></div>
    </div>

    <div class="pt-4 mb-4 mb-lg-3 pb-lg-4">
      <div class="row g-4">
        <div class="col-auto">
          <div class="avatar-lg"><img v-bind:src="data.article.urlAvatar" alt="user-img"
              class="img-thumbnail rounded-circle"></div>
        </div>
        <div class="col">
          <div class="p-2">
            <h3 class="text-white mb-1">{{ data.article.title }}</h3>
            <p class="text-white-75">
              <router-link :to="{ name: 'blog', params: { blogLink: this.$route.params.blogLink } }">
                {{ data.blog.title }}
              </router-link>
            </p>
          </div>
        </div>
        <div class="col-12 col-lg-auto order-last order-lg-0">
          <div class="row text text-white-50 text-center">
            <div class="col-lg-6 col-4">
              <div class="p-2">
                <h4 class="text-white mb-1">24.3K</h4>
                <p class="fs-14 mb-0">Followers</p>
              </div>
            </div>
            <div class="col-lg-6 col-4">
              <div class="p-2">
                <h4 class="text-white mb-1">1.3K</h4>
                <p class="fs-14 mb-0">Following</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Container>
      <template #sidebar>

        <div class="card">
          <div class="card-body">
            <h5 class="card-title mb-3">Info</h5>
            <div class="table-responsive">
              <table class="table table-borderless mb-0">
                <tbody>
                  <tr>
                    <th class="ps-0" scope="row">Posted:</th>
                    <td class="text-muted">{{ data.createdAt }}</td>
                  </tr>
                  <tr>
                    <th class="ps-0" scope="row">Mobile :</th>
                    <td class="text-muted">+(1) 987 6543</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </template>
      <template #content>

        <div class="card">
          <div class="card-body" v-html="data.article.memo"></div>
        </div>

      </template>
    </Container>

  </Layout>
</template>